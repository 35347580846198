<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Cập nhật thông tin vai trò
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Tên vai trò -->
          <validation-provider
            #default="validationContext"
            name="Tên vai trò"
            rules="required"
          >
            <b-form-group
              label="Tên vai trò"
              label-for="ten-vai-tro"
            >
              <b-form-input
                id="ten-vai-tro"
                v-model="userData.name"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Tên vai trò"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              <feather-icon
                icon="CloudIcon"
                class="mr-50"
              />
              Lưu lại
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Hủy
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import { getUserData } from '@/auth/utils'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
    }
  },
  mounted() {
    const currentUser = getUserData()

    this.$root.$on('updateKhach', id => {
      store.state.showBlock = true
      store
        .dispatch('app-vai-tro/load', {
          auth: currentUser.auth_key,
          uid: currentUser.id,
          vai_tro: id,
        })
        .then(response => {
          this.userData = response.data.result
          store.state.showBlock = false
        })
        .catch(e => {
          store.state.showBlock = false
          this.showToast('danger', 'Thông báo', 'BellIcon', e.message)
        })
    })
  },
  created() {

  },
  setup(props, { emit }) {
    const currentUser = getUserData()
    const toast = useToast()

    const blankUserData = {
      id: '',
      name: '',
    }

    const listVaiTro = ref([])

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }
    const setUserData = user => {
      userData.value = user
    }
    const showToast = (variant, titleNotification, iconNotification, contentNotification) => {
      toast({
        component: ToastificationContent,
        props: {
          title: titleNotification,
          icon: iconNotification,
          text: contentNotification,
          variant,
        },
      })
    }

    const onSubmit = () => {
      store.state.showBlock = true
      Object.assign(userData.value, {
        auth: currentUser.auth_key,
        uid: currentUser.id,
      })
      store.dispatch('app-vai-tro/save', userData.value)
        .then(response => {
          showToast('success', 'Thông báo', 'BellIcon', response.data.content)
          emit('refetch-data')
          emit('update:is-add-new-user-sidebar-active', false)
          store.state.showBlock = false
        })
        .catch(e => {
          if (typeof e.response !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.response.data.message)
          } else if (typeof e.message !== 'undefined') {
            showToast('danger', 'Thông báo', 'BellIcon', e.message)
          } else {
            showToast('danger', 'Thông báo', 'BellIcon', e)
          }
          store.state.showBlock = false
        })
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetuserData)

    return {
      listVaiTro,
      userData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      setUserData,
    }
  },
  methods: {

  },
}
</script>

<style lang="scss">

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>

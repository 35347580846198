import axios from '@axios'
import config from '../../config.json'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchData(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}vai-tro/get-data`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    delete(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}vai-tro/delete`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    save(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}vai-tro/save`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    load(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${config.apiUrl.mainUrl}vai-tro/load`, queryParams, {
            headers: {
              'Content-Type': 'multipart/json',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
